// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import JQuery from 'jquery'
import _ from 'underscore'
import "channels"
// Tailwind CSS
import "stylesheets/application" // ADD THIS LINE

require("trix")
require("@rails/actiontext")

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

window.$ = JQuery
window._ = _



function importAll (r) {
  r.keys().forEach(r)
}

importAll(require.context('../main/', true, /\.js$/))

require("trix")
require("@rails/actiontext")
