
$.ajaxSetup({
  headers: {
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
  }
});

String.prototype.hashCode = function() {
    var hash = 0;
    for (var i = 0; i < this.length; i++) {
        var char = this.charCodeAt(i);
        hash = ((hash<<5)-hash)+char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  }

    var colors =  ['#FDE68A','#BEF264', '#86EFAC', '#5EEAD4', '#67E8F9', '#7DD3FC', '#93C5FD', '#A5B4FC', '#C4B5FD', '#F0ABFC', '#F9A8D4', '#F8B195', "#F67280", "#C06C84", "#6C5B7B", "#355C7D",  "#A7226E",   "#EC2049",   "#F26B38",   "#F7DB4F",   "#2F9599", "#FC913A", "#FF4E50", "#9DE0AD", "#45ADA8", "#547980", "#fe4a49", "#2ab7ca", "#0e9aa7", "#3da4ab", "#f6cd61", "#fe8a71", "#edc951", "#eb6841", "#cc2a36", "#00a0b0", '#0984e3', '#00d2d3', '#F8C06C', '#E66577', '#E66577', '#00d2d3', '#9a53ee', '#585af4', '#F8C06C', '#a1bfc0']

  // hash a word and map to a color consistently
window.colorForString = function(str){
    return colors[Math.abs(str.toLowerCase().trim().hashCode())%colors.length]
  }

window.textColorForString = function(str){
    if(isDark(str)){
      return '#ffffffd1'
    }else{
      return '#00000091'
    }
  }


window.toTitleCase = function(str) {
    str = str.toLowerCase()
    return str.replace(/(?:^|\s)\w/g, function(match) {
        return match.toUpperCase();
    });
  }


window.isDark = function(color) {

    // Variables for red, green, blue values
    var r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

        r = color[1];
        g = color[2];
        b = color[3];
    }
    else {

        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace(
        color.length < 5 && /./g, '$&$&'));

        r = color >> 16;
        g = color >> 8 & 255;
        b = color & 255;
    }

    hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp>200.5) {

        return false;
    }
    else {

        return true;
    }
}


//
